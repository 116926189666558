import React, { useState, useEffect } from "react";
import { storage } from "api/firebase";

import {
  addPromotionalWorkshopCompletion,
  getPromotionalWorkshopCompletions
} from "api/db";

const PersonalWebsiteWebinar = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [completions, setCompletions] = useState([]);

  useEffect(() => {
    getPromotionalWorkshopCompletions(snapshot => {
      let data = snapshot.val();
      const object = { ...data };
      setCompletions(existing => existing.concat(object));
    });
  }, []);

  return (
    <>
      <main className="relative mx-auto max-w-screen-xl">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 z-10">
          <div className="text-center">
            <img
              className="w-32 mx-auto"
              src="https://startupheretoronto.com/wp-content/uploads/2016/09/velocity.uwaterloo.cawp-contentuploads201608assets_blue-325f175ddea29c70e13a4e2b41e23042a186fb3b.png"
            />
            {/* <div
              className="py-1 bg-blue-800 hover:bg-blue-700 items-center text-blue-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                Hack the North
              </span>
            </div> */}
          </div>
          <div className="text-center">
            <h2 className="text-4xl tracking-tight font-bold text-gray-900 ">
              Intro to Web Development Workshop
            </h2>
          </div>

          <section className="max-w-2xl mx-auto mt-4">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Name
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-100"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div class="w-full md:w-1/3 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Website URL
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
                  type="text"
                  placeholder="https://"
                  value={url}
                  onChange={e => {
                    let url = e.target.value;

                    setUrl(e.target.value);
                  }}
                />
              </div>
              <div class="w-full md:w-1/3 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Image (Screenshot)
                </label>

                <input
                  className="text-xs my-2"
                  accept="image/*"
                  onChange={e => {
                    const image = e.target.files[0];
                    let size = image.size / 1024 / 1024; // in MB
                    if (size > 10) {
                      alert("File size exceeds 10 MB");
                    } else {
                      const upload = storage
                        .ref(`/workshopSubmissions/${image.name}`)
                        .put(image);

                      setUploading(true);

                      upload.on(
                        "state_changed",
                        snapshot => {
                          // console.log(snapshot);
                        },
                        err => {
                          // console.log(err);
                        },
                        () => {
                          storage
                            .ref("workshopSubmissions")
                            .child(image.name)
                            .getDownloadURL()
                            .then(url => {
                              setUploading(false);
                              setImage(url);
                            });
                        }
                      );
                    }
                  }}
                  type="file"
                />
              </div>
            </div>
            <div className="flex  justify-center mb-4">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={() => {
                  if (name.length > 2 && url.length > 5) {
                    addPromotionalWorkshopCompletion(name, url, image);
                    setName("");
                    setUrl("");
                  } else {
                    alert("Please fill out both fields.");
                  }
                }}
              >
                {uploading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </section>

          <div className="p-4">
            <div className="text-right">
              <b>{completions.length}</b> submissions
            </div>
            <div className="flex flex-wrap justify-center">
              {completions.map(completion => (
                <div
                  className="rounded overflow-hidden shadow text-center m-2 p-4"
                  key={completion.url}
                >
                  <p className="text-gray-900">
                    {completion.name} built a{" "}
                    <a
                      target="_blank"
                      href={completion.url}
                      className="text-blue-600 font-bold"
                    >
                      personal website
                    </a>{" "}
                    &#8250;
                  </p>

                  {completion.img && (
                    <a target="_blank" href={completion.url}>
                      <img
                        className="h-24 shadow-md m-2 inline"
                        src={completion.img}
                      />
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
      </main>
    </>
  );
};

export default PersonalWebsiteWebinar;
